import { USER_DATA } from '@/constants/constants'

export default () => {
  const getIdTenant = () => {
    return JSON.parse(localStorage.getItem(USER_DATA)).tenant.id
  }

  const sendDataToParentClient = (HHID, isNew = false) => {
    const heuristikMessage = {
      HHID: HHID,
      isNew: isNew
    }
    window.parent.postMessage(heuristikMessage, '*')
  }

  // ----- //
  // DATES //
  // ----- //

  const getDayFirstSecond = (date) => {
    return formatDateForServices(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
  }

  // Send next day/month/year start instead last second of day/month/year until backend fix getting hour correctly
  const getDayLastSecond = (date) => {
    // return formatDateForServices(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59))
    return formatDateForServices(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1))
  }

  const getMonthFirstDay = (date) => {
    return formatDateForServices(new Date(date.getFullYear(), date.getMonth(), 1))
  }

  // Send next day/month/year start instead last second of day/month/year until backend fix getting hour correctly
  const getMonthLastDay = (date) => {
    // return formatDateForServices(new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 29))
    return formatDateForServices(new Date(date.getFullYear(), date.getMonth() + 1, 1))
  }

  const getYearFirstDay = (date) => {
    return formatDateForServices(new Date(date.getFullYear(), 0, 1))
  }

  // Send next day/month/year start instead last second of day/month/year until backend fix getting hour correctly
  const getYearLastDay = (date) => {
    // return formatDateForServices(new Date(date.getFullYear(), 11, 31, 23, 59, 59))
    return formatDateForServices(new Date(date.getFullYear() + 1, 0, 1))
  }

  // --- //

  const getTomorrowFromNowOnISO = () => {
    const now = new Date()
    return new Date(now.setDate(now.getDate() + 1)).toISOString()
  }

  const getYesterdayFromNowOnISO = () => {
    const now = new Date()
    return new Date(now.setDate(now.getDate() - 1)).toISOString()
  }

  /**
   * A function for extract the hour from a full date string with a particular format from backend
   * @param {String} fullDate - String of date with this format: Mon d, YYYY, h:mm:ss AM
   * @returns A substring from the second comma to the end, with the hour: hh:mm:ss AM
   */
  const getOnlyHour = (fullDate) => {
    return fullDate.split(',')[2]
  }

  /**
   * A function for extract the date without hour from a full date string with a particular format from backend
   * @param {String} fullDate - String of a date with this format: Mon dd, YYYY, h:mm:ss AM
   * @returns A substring from the beginning to the second comma, with the date: Mon d, YYYY
   */
  const getOnlyDate = (fullDate) => {
    const fullDateSplitted = fullDate.split(',')
    return `${fullDateSplitted[0]}${fullDateSplitted[1]}`
  }

  // "YYYY-MM-DD hh:mm:ss"
  const formatDateForServices = (date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
  }

  return {
    formatDateForServices,
    getDayFirstSecond,
    getDayLastSecond,
    getIdTenant,
    getMonthFirstDay,
    getMonthLastDay,
    getOnlyDate,
    getOnlyHour,
    getTomorrowFromNowOnISO,
    getYearFirstDay,
    getYearLastDay,
    getYesterdayFromNowOnISO,
    sendDataToParentClient
  }
}
