<template>
  <div class="he-ui-search-recents-list-container d-flex flex-column align-items-start justify-content-start">
    <!-- Search @TODO make a common component-->
    <div class="searcher d-flex justify-content-start align-items-center">
      <!-- @TODO Mocked asset -->
      <span class="icon">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.007 14.7057L9.72075 10.4187C7.814 11.7743 5.18658 11.4427 3.67641 9.65592C2.16623 7.86911 2.27712 5.22316 3.9315 3.56897C5.58545 1.91406 8.23165 1.80269 10.0188 3.31278C11.8059 4.82288 12.1377 7.45056 10.782 9.35747L15.0683 13.6445L14.0078 14.705L14.007 14.7057ZM7.113 3.75046C5.69078 3.75014 4.46377 4.74848 4.17486 6.14105C3.88595 7.53362 4.61454 8.93768 5.91951 9.50315C7.22449 10.0686 8.74718 9.6401 9.56569 8.47701C10.3842 7.31393 10.2736 5.73596 9.30075 4.69847L9.7545 5.14847L9.243 4.63847L9.234 4.62947C8.67284 4.06485 7.90905 3.74832 7.113 3.75046Z" fill="#141414"/>
        </svg>
      </span>
      <!-- @TODO update translation literal -->
      <input
        type='text'
        v-model="stringToSearch"
        placeholder="Buscar..."
        @input.stop="onChangeInput"
      />
    </div>

    <!-- Selected -->
    <div class="card-selected d-flex justify-content-start align-items-center">
      <!-- @TODO Mocked asset -->
      <div class="icon">
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.209 11.6667C10.209 7.63959 13.4736 4.375 17.5007 4.375C21.5277 4.375 24.7923 7.63959 24.7923 11.6667C24.7923 15.6937 21.5277 18.9583 17.5007 18.9583C13.4736 18.9583 10.209 15.6937 10.209 11.6667ZM17.5007 16.0417C19.9169 16.0417 21.8757 14.0829 21.8757 11.6667C21.8757 9.25042 19.9169 7.29167 17.5007 7.29167C15.0844 7.29167 13.1257 9.25042 13.1257 11.6667C13.1257 14.0829 15.0844 16.0417 17.5007 16.0417Z" fill="#368AFF"/>
          <path d="M9.25107 23.8338C7.06315 26.0217 5.83398 28.9891 5.83398 32.0833H8.75065C8.75065 29.7627 9.67252 27.5371 11.3135 25.8961C12.9544 24.2552 15.18 23.3333 17.5007 23.3333C19.8213 23.3333 22.0469 24.2552 23.6878 25.8961C25.3288 27.5371 26.2507 29.7627 26.2507 32.0833H29.1673C29.1673 28.9891 27.9382 26.0217 25.7502 23.8338C23.5623 21.6458 20.5948 20.4167 17.5007 20.4167C14.4065 20.4167 11.439 21.6458 9.25107 23.8338Z" fill="#368AFF"/>
        </svg>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-start">
        <div class="title">Paciente:</div>
        <div class="data">HHID: {{ selectedHHID }} </div>
      </div>
    </div>

    <!-- Recents list -->
    <!-- @TODO update translation literal -->
    <div class="recents-header">Recientes:</div>
    <div class="hide-scroll">
      <div class="recents-list-container">
        <div
          v-for="recentPill in getRecentIdentifications"
          :key="recentPill.index"
          class="pill d-flex justify-content-start align-items-center"
          @click="onRecentClick(recentPill.HHID)"
        >
          HHID: {{recentPill.HHID}}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validateHHIDFormat } from '@/composition/validators'
import fingerprintReader from '@/composition/fingerprintReader'
import mixins from '@/composition/mixins'

export default {

  setup () {
    const { getSerialNumber } = fingerprintReader()
    const { getTomorrowFromNowOnISO, getYesterdayFromNowOnISO } = mixins()

    return {
      getSerialNumber,
      getTomorrowFromNowOnISO,
      getYesterdayFromNowOnISO
    }
  },

  data () {
    return {
      stringToSearch: '',
      selectedHHID: ''
    }
  },

  computed: {
    ...mapGetters([
      'getRecentIdentifications',
      'getSelectedHHID'
    ])
  },

  methods: {
    ...mapActions([
      'fetchRecentIdentifications',
      'fetchActivityById'
    ]),
    onChangeInput () {
      if (validateHHIDFormat(this.stringToSearch)) {
        this.getHHIDIdentifications(this.stringToSearch)
      }
    },
    onRecentClick (HHID) {
      this.getHHIDIdentifications(HHID)
    },
    getHHIDIdentifications (HHID) {
      this.getSerialNumber()
        .then((response) => {
          const params = {
            sensorSN: response,
            dateInit: this.getYesterdayFromNowOnISO(),
            dateEnd: this.getTomorrowFromNowOnISO(),
            hhid: HHID
          }
          this.fetchActivityById(params)
          this.selectedHHID = HHID
        })
        .catch((e) => {
          console.log('Error capturing sensor serial number', e)
        })
    },
    getRecents () {
      this.getSerialNumber()
        .then((response) => {
          const params = {
            sensorSN: response,
            dateInit: this.getYesterdayFromNowOnISO(),
            dateEnd: this.getTomorrowFromNowOnISO()
          }
          this.fetchRecentIdentifications(params)
        })
        .catch((e) => {
          console.log('Error capturing sensor serial number', e)
        })
    }
  },

  mounted () {
    this.getRecents()
  }

}
</script>

<style lang="scss" scoped>
  .he-ui-search-recents-list-container {
    height: 83.8vh;
    // width: 295px;
    width: 332px;
    min-width: 332px;
    border-radius: 14px 14px 0px 0px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
    background: #FFF;
    margin-top: 14px;
    padding: 16px 16px 0px 16px;

    position: sticky;
    left: 0;
    z-index: 10;
  }

  .searcher {
    height: 34px;
    width: 100%;
    margin-bottom: 14px;
    border-radius: 14px;
    background: rgba(217, 217, 217, 0.20);
    .icon {
      padding-left: 12px;
      padding-right: 8px;
    }
    input {
      background: rgba(217, 217, 217, 0);
      border: none;
      width: 70%;
      height: 100%;
      color: #000;
      font-size: 11px;
      font-weight: 500;
      &:focus-visible {
        outline-color: rgba(217, 217, 217, 0.20);
      }
    }
  }

  .card-selected {
    width: 100%;
    border-radius: 14px;
    background: rgba(54, 138, 255, 0.10);
    .icon {
      padding: 25px 11px 25px 25px;
    }
    .title {
      color: #000;
      font-size: 11px;
      line-height: 120%;
    }
    .data {
      color: #000;
      font-size: 13px;
      font-weight: 800;
      line-height: 120%;
    }
  }

  .recents-header {
    color: #AEAEAE;
    font-size: 15px;
    font-weight: 800;
    margin-top: 25px;
    margin-left: 11px;
  }
  .recents-list-container {
    overflow-y: auto;
    height: 100%;
    max-height: 62vh;
    width: 100%;
    // margin-right: -100px;
    // padding-right: 100px;
    .pill {
      border-radius: 14px;
      background: #FFF;
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
      margin-top: 10px;
      color: #000;
      font-size: 11px;
      padding-left: 15px;
      height: 34px;
      cursor: pointer;
    }
  }
  .hide-scroll {
    overflow: hidden;
    width: 100%;
  }
  // Hide scrollbars keeping scroll feature
  ::-webkit-scrollbar {
    display: none;
  }
</style>
